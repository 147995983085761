import React from 'react';
import Fetch from "../../utils/Fetch";
import Util from "../../utils/Util";

import Main from "../../components/Main";
import ExchangeStats from "../../components/ExchangeStats";

import styles from './Home.module.css';
export default class Home extends React.Component {

    constructor(props) {
        super();

        this.state = {
            exchanges: [],
            total_usd: 0,
            total_btc: 0,
            total_eth: 0,
            total_ltc: 0,
            total_xrp: 0,
            total_usd_all: 0,
        }
    }

    componentDidMount(){
        // let options = {
        //     method: 'POST',
        //     body: JSON.stringify(body),
        //     headers: {
        //       'bfx-apikey': this.api_key,
        //       'bfx-signature': signature,
        //       'bfx-nonce': nonce,
        //       'Content-Type': 'application/json',
        //       'Accept': 'application/json',
        //     }
        // }
  
        let url = "/api/web/home.php";
  
        Fetch.request(url)
            .then((response) => response.json())
            .then((json) => {
                var exchanges = json.exchanges;
                var total_usd = 0;
                var total_btc = 0;
                var total_eth = 0;
                var total_ltc = 0;
                var total_xrp = 0;
                var total_usd_all = 0;

                var total_btc_usd = 0;
                var total_eth_usd = 0;
                var total_ltc_usd = 0;
                var total_xrp_usd = 0;

                for(var i = 0; i < exchanges.length; i++){
                    total_usd += exchanges[i].usd_available;
                    total_btc += exchanges[i].btc_available;
                    total_eth += exchanges[i].eth_available;
                    total_ltc += exchanges[i].ltc_available;
                    total_xrp += exchanges[i].xrp_available;

                    total_btc_usd += (exchanges[i].btc_available * exchanges[i].btc_usd);
                    total_eth_usd += (exchanges[i].eth_available * exchanges[i].eth_usd);
                    total_ltc_usd += (exchanges[i].ltc_available * exchanges[i].ltc_usd);
                    total_xrp_usd += (exchanges[i].xrp_available * exchanges[i].xrp_usd);
                }

                total_usd_all = total_usd+total_btc_usd+total_eth_usd+total_ltc_usd+total_xrp_usd;

                this.setState({
                    exchanges: exchanges,
                    total_usd: total_usd,
                    total_btc: total_btc,
                    total_eth: total_eth,
                    total_ltc: total_ltc,
                    total_xrp: total_xrp,
                    total_usd_all: total_usd_all,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }


    render() {

        if(this.state.exchanges.length === 0){
            return null;
        }

        return (

            <Main>
        	    <div className={styles.root}>
                    <div>
                        <div className={styles.overview}></div>
                        <div className={styles.graph}></div>
                    </div>

                    <div className={styles.exchangeStats}>

                        <div className={styles.dividerTop}></div>

                        <div className={styles.coinsTable}>
                            <div className={styles.firstRow}>&nbsp;</div>
                            <div className={styles.row}>Taker Fee</div>
                            <div className={styles.row}>USDt</div>
                            <div className={styles.row}>BTC</div>
                            <div className={styles.row}>ETH</div>
                            <div className={styles.row}>LTC</div>
                            <div className={styles.row}>XRP</div>
                            <div className={styles.row}>&nbsp;</div>
                        </div>

                        {this.state.exchanges.map((row, index) =>
                            <ExchangeStats 
                                key={row.name}
                                index={index}
                                name={row.name}
                                trade_fee_pr={row.trade_fee_pr}

                                usd_withdraw_fee={row.usd_withdraw_fee}
                                btc_withdraw_fee={row.btc_withdraw_fee}
                                eth_withdraw_fee={row.eth_withdraw_fee}
                                ltc_withdraw_fee={row.ltc_withdraw_fee}
                                xrp_withdraw_fee={row.xrp_withdraw_fee}

                                usd_available={row.usd_available}
                                btc_available={row.btc_available}
                                eth_available={row.eth_available}
                                ltc_available={row.ltc_available}
                                xrp_available={row.xrp_available}

                                btc_usd={row.btc_usd}
                                eth_usd={row.eth_usd}
                                ltc_usd={row.ltc_usd}
                                xrp_usd={row.xrp_usd}
                            />
                        )}

                        <div className={styles.totalTable}>
                            <div className={styles.firstRow}>Total</div>
                            <div className={styles.row}>&nbsp;</div>
                            <div className={styles.row}>{Util.formatNumber(this.state.total_usd, 2, 2)}</div>
                            <div className={styles.row}>{Util.formatNumber(this.state.total_btc, 2, 8)}</div>
                            <div className={styles.row}>{Util.formatNumber(this.state.total_eth, 2, 8)}</div>
                            <div className={styles.row}>{Util.formatNumber(this.state.total_ltc, 2, 8)}</div>
                            <div className={styles.row}>{Util.formatNumber(this.state.total_xrp, 2, 2)}</div>
                            <div className={styles.row+" "+styles.lastRow}>{Util.formatNumber(this.state.total_usd_all, 2, 2)}</div>
                        </div>

                        <div className={styles.dividerBottom}></div>

                    </div>
                </div>
            </Main>

        );
    }
}
