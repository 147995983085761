import React from 'react';

import styles from './Page404.module.css';
export default class Page404 extends React.Component {

    render() {

        return (

            <div className={styles.root}>Page404</div>

        );
    }
}

