import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
  } from "react-router-dom";

import Home from './pages/Home';
import Login from './pages/Login';
import Page404 from './pages/Page404';

export default class App extends React.Component {

    render() {

        return (
            <Router>
                <Switch>
                    
                    <Route exact path="/">
                        <Home />
                    </Route>

                    <Route path="/home">
                        <Home />
                    </Route>

                    <Route path="/login">
                        <Login />
                    </Route>
                    
                    <Route path="*">
                        <Page404 />
                    </Route>

                </Switch>
            </Router>

        );
    }
}