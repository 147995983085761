import React from 'react';
import PropTypes from 'prop-types';

import Util from "../../utils/Util";

import styles from './ExchangeStats.module.css';
export default class ExchangeStats extends React.Component {

    static propTypes = {
        index: PropTypes.number,
        name: PropTypes.string,
        trade_fee_pr: PropTypes.number,

        usd_withdraw_fee: PropTypes.number,
        btc_withdraw_fee: PropTypes.number,
        eth_withdraw_fee: PropTypes.number,
        ltc_withdraw_fee: PropTypes.number,
        xrp_withdraw_fee: PropTypes.number,

        usd_available: PropTypes.number,
        btc_available: PropTypes.number,
        eth_available: PropTypes.number,
        ltc_available: PropTypes.number,
        xrp_available: PropTypes.number,

        btc_usd: PropTypes.number,
        eth_usd: PropTypes.number,
        ltc_usd: PropTypes.number,
        xrp_usd: PropTypes.number,
    }

    static defaultProps = {
        index: 0,
        name: "binance",
        trade_fee_pr: 0,

        usd_withdraw_fee: 0,
        btc_withdraw_fee: 0,
        eth_withdraw_fee: 0,
        ltc_withdraw_fee: 0,
        xrp_withdraw_fee: 0,

        usd_available: 0,
        btc_available: 0,
        eth_available: 0,
        ltc_available: 0,
        xrp_available: 0,

        btc_usd: 0,
        eth_usd: 0,
        ltc_usd: 0,
        xrp_usd: 0,
    };

    render() {

        var evenClass = "";
        if(this.props.index % 2 === 0){
            evenClass = styles.even;
        }

        let tradeFeePr = Util.formatNumber(this.props.trade_fee_pr);

        let usdAvailable = Util.formatNumber(this.props.usd_available);
        let usdWdFee = "wf "+Util.formatNumber(this.props.usd_withdraw_fee)+"$";

        let btcAvailable = Util.formatNumber(this.props.btc_available, 2, 8);
        let btcUsdAvailable = Util.formatNumber(this.props.btc_available * this.props.btc_usd);
        let btcWdFee = "wf "+Util.formatNumber(this.props.btc_withdraw_fee * this.props.btc_usd)+"$";

        let ethAvailable = Util.formatNumber(this.props.eth_available, 2, 8);
        let ethUsdAvailable = Util.formatNumber(this.props.eth_available * this.props.eth_usd);
        let ethWdFee = "wf "+Util.formatNumber(this.props.eth_withdraw_fee * this.props.eth_usd)+"$";

        let ltcAvailable = Util.formatNumber(this.props.ltc_available, 2, 8);
        let ltcUsdAvailable = Util.formatNumber(this.props.ltc_available * this.props.ltc_usd);
        let ltcWdFee = "wf "+Util.formatNumber(this.props.ltc_withdraw_fee * this.props.ltc_usd)+"$";

        let xrpAvailable = Util.formatNumber(this.props.xrp_available, 2, 8);
        let xrpUsdAvailable = Util.formatNumber(this.props.xrp_available * this.props.xrp_usd);
        let xrpWdFee = "wf "+Util.formatNumber(this.props.xrp_withdraw_fee * this.props.xrp_usd)+"$";

        let total = this.props.usd_available
                    +(this.props.btc_available * this.props.btc_usd)
                    +(this.props.eth_available * this.props.eth_usd)
                    +(this.props.ltc_available * this.props.ltc_usd)
                    +(this.props.xrp_available * this.props.xrp_usd);
        total = Util.formatNumber(total);

        return (

            <div className={styles.root+" "+evenClass}>
                <div className={styles.firstRow}>
                    <img src={"/static/img/logo_"+this.props.name+".svg"} alt={this.props.name} />
                    <span className={styles.name}>{this.props.name}</span>
                </div>
                <div className={styles.row}>{tradeFeePr} %</div> {/* trade fee pr */}

                <div className={styles.row}>
                    {/* usd */}
                    <span className={styles.usdAvailable}>{usdAvailable}</span>
                    <span className={styles.wdFee}>{usdWdFee}</span>
                </div> 

                <div className={styles.row}>
                    {/* btc */}
                    <span className={styles.coinAvailable}>{btcAvailable}</span>
                    <span className={styles.usdAvailable}>{btcUsdAvailable}</span>
                    <span className={styles.wdFee}>{btcWdFee}</span>
                </div> 

                <div className={styles.row}>
                    {/* eth */}
                    <span className={styles.coinAvailable}>{ethAvailable}</span>
                    <span className={styles.usdAvailable}>{ethUsdAvailable}</span>
                    <span className={styles.wdFee}>{ethWdFee}</span>
                </div> 

                <div className={styles.row}>
                    {/* ltc */}
                    <span className={styles.coinAvailable}>{ltcAvailable}</span>
                    <span className={styles.usdAvailable}>{ltcUsdAvailable}</span>
                    <span className={styles.wdFee}>{ltcWdFee}</span>
                </div> 

                <div className={styles.row}>
                    {/* xrp */}
                    <span className={styles.coinAvailable}>{xrpAvailable}</span>
                    <span className={styles.usdAvailable}>{xrpUsdAvailable}</span>
                    <span className={styles.wdFee}>{xrpWdFee}</span>
                </div> 

                <div className={styles.row+" "+styles.lastRow}>
                    {/* total */}
                    {total}
                </div> 
            </div>

        );
    }
}

