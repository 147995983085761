export default class Util{

    static formatNumber(value, min_decimals = 2, max_decimals = 2) {

        if(value === 0){
            return value.toLocaleString('ja-JP', {minimumFractionDigits: 2, maximumFractionDigits: 2});
        }

        return value.toLocaleString('ja-JP', {minimumFractionDigits: min_decimals, maximumFractionDigits: max_decimals});

    }

}