import React from 'react';

import MenuLeft from "../MenuLeft";

import styles from './Main.module.css';
export default class Main extends React.Component {

    render() {

        return (

            <div className={styles.root}>
                <div className={styles.left}>
                    <MenuLeft />
                </div>
                <div className={styles.right}>
                    {this.props.children}
                </div>
            </div>

        );
    }
}

