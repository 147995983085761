import React from 'react';

import styles from './MenuLeft.module.css';
export default class MenuLeft extends React.Component {

    render() {

        return (

            <div className={styles.root}></div>

        );
    }
}

