import React from 'react';

import styles from './Login.module.css';
export default class Login extends React.Component {

    render() {

        return (

            <div className={styles.root}>Login</div>

        );
    }
}

