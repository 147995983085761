module.exports = {
    request(url, options = {}, timeout = 10000) {

        return new Promise((resolve, reject) => {
            // Set timeout timer
            let timer = setTimeout(
                () => reject(new Error('Request timed out')),
                timeout
            );

            fetch(url, options).then(
                response => resolve(response),
                err => reject(err)
            ).finally(() => clearTimeout(timer));
        })

    }
}